import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../components/breadcrumb'
import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Link from '../components/link'
import List from '../components/list'
import ListItem from '../components/list-item'
import Paragraph from '../components/paragraph'
import SEO from '../components/seo'


export const frontmatter = {
  body: 'Pyramid',
  slug: '/pyramid/',
  title: 'Pyramid',
}

export const query = graphql`
  query LayersPageQuery {
    ...SiteInfo
    imageMain: file(relativePath: { eq: "pyramid.jpg" }) { ...ImageMain }
    results: postgres {
      layers: allLayersList(orderBy: ID_DESC) {
        ...Layer
      }
    }
  }
`

export default ({ data }) => {
  const { layers } = data.results
  const { links } = data.site.siteMetadata
  const imageMain = data.imageMain.childImageSharp.fixed

  const page = 'Pyramid'
  const title = `Fragrance ${page}`
  const description = `
    The Fragrance Pyramid is a grouping of Notes into categories based on the
    time it takes, after application of the fragrance, to detect those
    scents. Notes which evaporate quickly are sensed first, while those that
    evaporate and dry down slowly are detected at a later time. Notes are
    separated into three classes: Top/Head notes, Middle/Heart notes, and
    Bottom/Base notes.
  `
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: page,
      slug: links.layers,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description,
    image: imageMain.src,
    keywords,
    name: title,
    things: layers,
    slug: links.layers,
  }

  return (
    <Layout>
      <SEO
        description={description}
        keywords={keywords}
        schema={schema}
        title={title}
      />
      <Breadcrumb trail={trail} />

      <Heading>{title}</Heading>

      <Image
        alt={title}
        credit="pyramid.jpg"
        fixed={imageMain}
      />

      <Paragraph>
        {description}
      </Paragraph>

      <List>
        {layers.map(layer => (
          <ListItem key={layer.id}>
            <Link href={layer.slug}>
              {layer.name} Notes
            </Link>
          </ListItem>
        ))}
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
